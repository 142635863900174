import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import Layout from "../components/layout";
import marked from "marked";

class Documents extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const [banner] = get(this, "props.data.allContentfulBanner.edges");
    const [pageData] = get(this, "props.data.allContentfulDocuments.edges");

    return (
      <Layout location={this.props.location}>
        <div style={{ background: "#fff" }}>
          <Helmet
            title={`Documents - ${siteTitle}`}
            meta={[
              {
                name: "description",
                content: "Documents of Aoga Amata Newtown",
              },
              { name: "keywords", content: "daycare, wellington, newtown" },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <Hero data={banner.node} />
          <div className="wrapper">
            <div
              className="section-content"
              dangerouslySetInnerHTML={{
                __html: marked(pageData.node.content.content),
              }}
            ></div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Documents;

export const pageQuery = graphql`
  query DocumentsQuery {
    allContentfulBanner(
      filter: { contentful_id: { eq: "2ANw6UC1CH1C0icPOct9ns" } }
    ) {
      edges {
        node {
          title
          heroImage: image {
            fluid(
              maxHeight: 500
              resizingBehavior: PAD
              background: "rgb:FFFFFF"
            ) {
              src
              srcSet
              base64
            }
          }
        }
      }
    }
    allContentfulDocuments {
      edges {
        node {
          content {
            content
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
